import axios from '@/plugins/axios'

const imagetexts = {
  state: {
    imagetextsList: [],
    imagetextsListPage: 1,
    imagetextsArchive: [],
    imagetextsArchivePage: 0,
  },
  mutations: {
    setImageTextList(state, data) {
      state.imagetextsList = data
    },
    setTextListAdd(state, data) {
      state.imagetextsList = state.imagetextsList.concat(data)
    },
    setTextsArchive(state, data) {
      state.imagetextsArchive = data
    },
    setTextArchiveAdd(state, data) {
      state.imagetextsArchive = state.imagetextsArchive.concat(data)
    },
    deleteArchive(state, id) {
      const index = state.imagetextsArchive.findIndex((item) => item.id === id)
      state.imagetextsArchive.splice(index, 1)
    },
    setImageTextsListPage(state, data) {
      state.imagetextsListPage = data
    },
    setTextsArchivePage(state, data) {
      state.imagetextsArchivePage = data
    },
  },
  getters: {
    imagetextsList: (state) => state.imagetextsList,
    imagetextsArchive: (state) => state.imagetextsArchive,
    imagetextsListPage: (state) => state.imagetextsListPage,
    imagetextsArchivePage: (state) => state.imagetextsArchivePage,
  },
  actions: {
    fetchImageTextList({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/contributor/customs')
          .then((res) => {
            commit('setImageTextList', res.data.data)
            commit(
              'setImageTextsListPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextImageTextList({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/contributor/customs?page=${getters.imagetextsListPage + 1}`)
          .then((res) => {
            commit('setTextListAdd', res.data.data)
            commit(
              'setImageTextsListPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchTextArchive({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/contributor/customs/archive')
          .then((res) => {
            commit('setTextsArchive', res.data.data)
            commit(
              'setTextsArchivePage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextTextArchive({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/contributor/customs/archive?page=${
              getters.imagetextsArchivePage + 1
            }`,
          )
          .then((res) => {
            commit('setTextArchiveAdd', res.data.data)
            commit(
              'setTextsArchivePage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    importImageTexts({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/contributor/customs/import', data)
          .then((res) => {
            res.data.message &&
              commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addImageText({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/contributor/customs', data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Ad Image & text has been added',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteImageTextContributor({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/contributor/customs/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteArchivetext({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/contributor/customs/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateImageText({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/contributor/customs/' + data.id, data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Ad Image & text has been updated',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addTexts({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/contributor/customs/contribute', data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Image & Text is created',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    templeteImageText() {
      return new Promise((resolve, reject) => {
        axios({
          url: '/contributor/customs/template',
          method: 'GET',
          responseType: 'blob',
        })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchImageTextListUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/customs', { params })
          .then((res) => {
            commit('setImageTextList', res.data.data)
            commit('setImageTextsListPage', 1)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addTextUser({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/customs', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default imagetexts
