<template>
  <AppSidebar />
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer lg>
        <router-view />
      </CContainer>
    </div>
    <AppFooter />
  </div>
  <AppAside />
</template>
<script>
import { mapGetters } from 'vuex'
import { CContainer } from '@coreui/vue-pro'
import AppAside from '@/components/AppAside.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { mapActions } from 'vuex'
export default {
  name: 'DefaultLayout',
  components: {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  created() {
    this.fetchMe()
  },
  methods: {
    ...mapActions(['fetchMe']),
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  watch: {
    getUser() {
      if (this.getUser && this.getUser.profile_filled === false) {
        if (this.getUser.active_subscription) {
          this.$router.push('/user/questions')
        } else {
          this.$router.push('/user/plan')
        }
      }
    },
  },
}
</script>
