import axios from '@/plugins/axios'

const ads = {
  state: {
    templateAdsCustom: [],
    templateAdsCustomPage: 0,
    activeAdsCustom: [],
    oldsAdsCustom: [],
    oldsAdsCustomPage: 0,
    googleAdsCustom: {
      groups: [],
      template: false,
      website_url: 'https://',
    },
  },
  mutations: {
    setTemplateAds(state, data) {
      state.templateAdsCustom = data
    },
    setTemplateAdsAdd(state, data) {
      state.templateAdsCustom = state.templateAdsCustom.concat(data)
    },
    setTemplateAdsPage(state, data) {
      state.templateAdsCustomPage = data
    },
    setActiveAds(state, data) {
      state.activeAdsCustom = data
    },
    setOldsAds(state, data) {
      state.oldsAdsCustom = data
    },
    setOldsAdsPage(state, data) {
      state.oldsAdsCustomPage = data
    },
    setOldsAdsAdd(state, data) {
      state.templateAdsCustom = state.templateAdsCustom.concat(data)
    },
    setGoogleAds(state, data) {
      state.googleAdsCustom = data
    },
    setGoogleAdsStatus(state, data) {
      const index = state.googleAdsCustom.groups.findIndex(
        (item) => item.group_id === data.id,
      )
      if (index !== -1) state.googleAdsCustom.groups[index].active = data.active
    },
    setGoogleAdsUrl(state, data) {
      if (data === 'www') {
        state.googleAdsCustom.website_url = 'https://' + data
      } else {
        state.googleAdsCustom.website_url = data
      }
    },
    setGoogleAdsTemplate(state, data) {
      state.googleAdsCustom.template = data
    },
  },
  getters: {
    templateAdsCustom: (state) => state.templateAdsCustom,
    templateAdsCustomPage: (state) => state.templateAdsCustomPage,
    activeAdsCustom: (state) => state.activeAdsCustom,
    oldsAdsCustom: (state) => state.oldsAdsCustom,
    oldsAdsCustomPage: (state) => state.oldsAdsCustomPage,
    googleAdsCustom: (state) => state.googleAdsCustom,
  },
  actions: {
    // fetchTemplateAds({commit}) {
    //     commit('setLoader', true)
    //     return new Promise((resolve, reject) => {
    //         axios.get('/user/templates?page=1')
    //             .then((res) => {
    //                 commit('setTemplateAds', res.data.data)
    //                 commit('setTemplateAdsPage', res.data.meta.last_page === res.data.meta.current_page ? -1 : res.data.meta.current_page)
    //                 resolve(res);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });
    //     });
    // },
    // fetchNextTemplateAds({commit, getters}) {
    //     commit('setLoader', true)
    //     return new Promise((resolve, reject) => {
    //         axios.get(`/user/templates?page=${getters.templateAdsCustomPage + 1}`)
    //             .then((res) => {
    //                 commit('setTemplateAdsAdd', res.data.data)
    //                 commit('setTemplateAdsPage', res.data.meta.last_page === res.data.meta.current_page ? -1 : res.data.meta.current_page)
    //                 resolve(res);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });
    //     })
    // },
    createAdsCustom({ commit, dispatch }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/user-custom-ads', data)
          .then((res) => {
            dispatch('fetchMe')
            commit('addAlerts', { type: 'primary', text: 'Ad is created' })
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchActiveAdsCustom({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/user-custom-ads')
          .then((res) => {
            commit('setActiveAds', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchOldsAdsCustom({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/user-custom-ads/old?page=1')
          .then((res) => {
            commit('setOldsAds', res.data.data)
            commit(
              'setOldsAdsPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // fetchNextOldsAds({commit, getters}) {
    //     commit('setLoader', true)
    //     return new Promise((resolve, reject) => {
    //         axios.get(`/user/user-ads/old?page=${getters.templateAdsCustomPage + 1}`)
    //             .then((res) => {
    //                 commit('setOldsAdsAdd', res.data.data)
    //                 commit('setOldsAdsPage', res.data.meta.last_page === res.data.meta.current_page ? -1 : res.data.meta.current_page)
    //                 resolve(res);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });
    //     })
    // },
    deleteFBAdsCustom({ dispatch, commit }, payload) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/user-custom-ads/${payload.id}`)
          .then(() => {
            dispatch('fetchActiveAdsCustom')
            dispatch('fetchOldsAdsCustom')
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchGoogleDisplayAds({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        // axios.get(`/user/user-ads/google?year=${date.year}&month=${date.month}`)
        axios
          .get(`/user/user-display-ads/google`)
          .then((res) => {
            commit('setGoogleAds', {
              ...res.data.data,
              website_url: res.data.data.website_url || 'https://',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveGoogleDisplayAds({ getters, commit, dispatch }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/user-display-ads/google`, {
            ...getters.googleAdsCustom,
            groups: getters.googleAdsCustom.groups
              .filter((i) => i.active)
              .map((item) => ({
                active: item.active,
                group_id: item.group_id,
                name: item.name,
              })),
          })
          .then((res) => {
            dispatch('fetchMe')
            commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ads
