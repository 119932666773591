import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#5046e5',
    secondary: '#ebedef',
    error: '#ef376e',
    info: '#747af2',
    success: '#51cc8a',
    warning: '#fc0',
  },
}
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
})
export default vuetify
