import axios from '@/plugins/axios'

const googleads = {
  state: {
    googleadsList: [],
    googleadsListPage: 1,
    googleadsArchive: [],
    googleadsArchivePage: 0,
  },
  mutations: {
    setGoogleAdList(state, data) {
      state.googleadsList = data
    },
    setGoogleAdListAdd(state, data) {
      state.googleadsList = state.googleadsList.concat(data)
    },
    setGoogleAdsArchive(state, data) {
      state.googleadsArchive = data
    },
    setGoogleAdArchiveAdd(state, data) {
      state.googleadsArchive = state.googleadsArchive.concat(data)
    },
    deleteArchive(state, id) {
      const index = state.googleadsArchive.findIndex((item) => item.id === id)
      state.googleadsArchive.splice(index, 1)
    },
    setGoogleAdsListPage(state, data) {
      state.googleadsListPage = data
    },
    setGoogleAdsArchivePage(state, data) {
      state.googleadsArchivePage = data
    },
  },
  getters: {
    googleadsList: (state) => state.googleadsList,
    googleadsArchive: (state) => state.googleadsArchive,
    googleadsListPage: (state) => state.googleadsListPage,
    googleadsArchivePage: (state) => state.googleadsArchivePage,
  },
  actions: {
    fetchGoogleAdList({ commit }, type) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/contributor/googleads', { params: { type: type } })
          .then((res) => {
            commit('setGoogleAdList', res.data.data)
            commit(
              'setGoogleAdsListPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextGoogleAdList({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/contributor/googleads?page=${getters.googleadsListPage + 1}`)
          .then((res) => {
            commit('setGoogleAdListAdd', res.data.data)
            commit(
              'setGoogleAdsListPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchGoogleAdArchive({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/contributor/googleads/archive')
          .then((res) => {
            commit('setGoogleAdsArchive', res.data.data)
            commit(
              'setGoogleAdsArchivePage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextGoogleAdArchive({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/contributor/googleads/archive?page=${
              getters.googleadsArchivePage + 1
            }`,
          )
          .then((res) => {
            commit('setGoogleAdArchiveAdd', res.data.data)
            commit(
              'setGoogleAdsArchivePage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    importGoogleAds({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/contributor/googleads/import', data)
          .then((res) => {
            res.data.message &&
              commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addGoogleAd({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/contributor/googleads', data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Google Ad has been added',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteGoogleAdContributor({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/contributor/googleads/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteArchivetext({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/contributor/googleads/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateGoogleAd({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        var id = data.get('id')
        axios
          .post('/contributor/googleads/' + id, data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Google Ad has been updated',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addGoogleAds({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/contributor/googleads/contribute', data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'GoogleAds is created',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    templeteGoogleAd(type) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/contributor/googleads/template?type=' + type,
          method: 'GET',
          responseType: 'blob',
        })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchGoogleAdListUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/googleads', { params })
          .then((res) => {
            commit('setGoogleAdList', res.data.data)
            commit('setGoogleAdsListPage', 1)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addGoogleAdUser({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/googleads', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default googleads
