import axios from '@/plugins/axios'

const texts = {
  state: {
    textsList: [],
    textsListPage: 1,
    textsArchive: [],
    textsArchivePage: 0,
  },
  mutations: {
    setTextList(state, data) {
      state.textsList = data
    },
    setTextListAdd(state, data) {
      state.textsList = state.textsList.concat(data)
    },
    setTextsArchive(state, data) {
      state.textsArchive = data
    },
    setTextArchiveAdd(state, data) {
      state.textsArchive = state.textsArchive.concat(data)
    },
    deleteArchive(state, id) {
      const index = state.textsArchive.findIndex((item) => item.id === id)
      state.textsArchive.splice(index, 1)
    },
    setTextsListPage(state, data) {
      state.textsListPage = data
    },
    setTextsArchivePage(state, data) {
      state.textsArchivePage = data
    },
  },
  getters: {
    textsList: (state) => state.textsList,
    textsArchive: (state) => state.textsArchive,
    textsListPage: (state) => state.textsListPage,
    textsArchivePage: (state) => state.textsArchivePage,
  },
  actions: {
    fetchTextList({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/contributor/texts')
          .then((res) => {
            commit('setTextList', res.data.data)
            commit(
              'setTextsListPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextTextList({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/contributor/texts?page=${getters.textsListPage + 1}`)
          .then((res) => {
            commit('setTextListAdd', res.data.data)
            commit(
              'setTextsListPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchTextArchive({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/contributor/texts/archive')
          .then((res) => {
            commit('setTextsArchive', res.data.data)
            commit(
              'setTextsArchivePage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextTextArchive({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/contributor/texts/archive?page=${getters.textsArchivePage + 1}`,
          )
          .then((res) => {
            commit('setTextArchiveAdd', res.data.data)
            commit(
              'setTextsArchivePage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    importTexts({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/contributor/texts/import', data)
          .then((res) => {
            res.data.message &&
              commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addText({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/contributor/texts', data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Ad text has been added',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteTextContributor({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/contributor/texts/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteArchivetext({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/contributor/texts/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateText({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/contributor/texts/' + data.id, data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Ad text has been updated',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addTexts({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/contributor/texts/contribute', data)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: 'Texts is created' })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    templeteText() {
      return new Promise((resolve, reject) => {
        axios({
          url: '/contributor/texts/template',
          method: 'GET',
          responseType: 'blob',
        })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchTextListUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/texts', { params })
          .then((res) => {
            commit('setTextList', res.data.data)
            commit('setTextsListPage', 1)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addTextUser({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/texts', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default texts
