import axios from '@/plugins/axios'

const questions = {
  state: {
    questions: [],
  },
  mutations: {
    setQuestions(state, data) {
      state.questions = data
    },
  },
  getters: {
    questions: (state) => state.questions,
  },
  actions: {
    fetchQuestions({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/profile')
          .then((res) => {
            commit('setQuestions', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getQuestionsData({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/profile/info')
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    sendQuestions({ commit, dispatch }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/profile', data)
          .then((res) => {
            dispatch('fetchMe')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    sendQuestionsValidate({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/profile/validate', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getAutocomplete({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/data/cities/get-location', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default questions
