import axios from '@/plugins/axios'

const texts = {
  state: {
    images: [],
    imagesPage: 0,
  },
  mutations: {
    setImages(state, data) {
      state.images = data
    },
    setImagesAdd(state, data) {
      state.images = state.images.concat(data)
    },
    setImagesPage(state, data) {
      state.imagesPage = data
    },
    deleteImageOne(state, id) {
      const index = state.images.findIndex((item) => item.id === id)
      state.images.splice(index, 1)
    },
  },
  getters: {
    getImages: (state) => state.images,
    getImagesPage: (state) => state.imagesPage,
  },
  actions: {
    fetchImages({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/contributor/images?page=${1}`)
          .then((res) => {
            commit('setImages', res.data.data)
            commit(
              'setImagesPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextImages({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/contributor/images?page=${getters.getImagesPage + 1}`)
          .then((res) => {
            commit('setImagesAdd', res.data.data)
            commit(
              'setImagesPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addImages(vm, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contributor/images', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteImage({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/contributor/images/' + id)
          .then((res) => {
            commit('deleteImageOne', id)
            res.data.message &&
              commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateImage({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/contributor/images/' + data.id, data.data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchImagesUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/images', { params })
          .then((res) => {
            commit('setImages', res.data.data)
            commit('setImagesPage', 1)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addImageUser({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/images', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default texts
