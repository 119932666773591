import axios from '@/plugins/axios'

const setting = {
  state: {
    intent: null,
    payments: [],
    planList: [],
    activePlan: null,
    googleCustomers: [],
    googleAccounts: [],
    facebookAccounts: [],
    facebookPage: [],
  },
  mutations: {
    setIntent(state, data) {
      state.intent = data
    },
    setPayments(state, data) {
      state.payments = data
    },
    setDefaultPayment(state, id) {
      state.payments = state.payments.map((item) => ({
        ...item,
        default: item.id === id,
      }))
    },
    deletePayment(state, id) {
      state.payments = state.payments.filter((item) => item.id !== id)
    },
    setPlanList(state, data) {
      state.planList = data
    },
    setActivePlan(state, data) {
      state.activePlan = data
    },
    setGoogleCustomers(state, data) {
      state.googleCustomers = data
    },
    setGoogleAccounts(state, data) {
      state.googleAccounts = data
    },
    setFacebookAccounts(state, data) {
      state.facebookAccounts = data
    },
    setFacebookPage(state, data) {
      state.facebookPage = data
    },
  },
  getters: {
    getIntent: (state) => state.intent,
    getPayments: (state) => state.payments,
    getPaymentsDefault: (state) => {
      const paymentsDefault = state.payments.find((item) => item.default)
      return (paymentsDefault && paymentsDefault.id) || null
    },
    getPlanList: (state) => state.planList,
    getActivePlan: (state) => state.activePlan,
    getGoogleCustomers: (state) => state.googleCustomers,
    getGoogleAccounts: (state) => state.googleAccounts,
    getFacebookAccounts: (state) => state.facebookAccounts,
    getFacebookPage: (state) => state.facebookPage,
  },
  actions: {
    editPassword({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/password-change`, data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Password is changed',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editInformation({ commit, dispatch }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/profile`, data)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Information is changed',
            })
            dispatch('fetchMe')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchIntent({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/intent')
          .then((res) => {
            commit('setIntent', res.data.data.intent)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchPayment({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/payments')
          .then((res) => {
            commit('setPayments', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addPayment({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/payments', { id: id })
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: 'Card is added' })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    editDefaultPayment({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/payments/default', { id: id })
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Default payment is changed',
            })
            commit('setDefaultPayment', id)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deletePayment({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/payments/' + id)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: 'Payment is deleted' })
            commit('deletePayment', id)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPlan({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/subscriptions')
          .then((res) => {
            commit('setPlanList', res.data.data)
            if (getters.getUser && getters.getUser.active_subscription) {
              const plan = res.data.data.find(
                (item) => item.id === getters.getUser.active_subscription.id,
              )
              commit('setActivePlan', plan)
            }
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getStripeSession({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/subscriptions/' + id + '/session')
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    successPayment({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/subscriptions/done', { id: id })
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: 'subscribed' })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updatePlan({ commit, dispatch, getters }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/subscriptions/' + id)
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Subscription is updated',
            })
            dispatch('fetchMe')
            commit(
              'setActivePlan',
              getters.getPlanList.find((item) => item.id === id),
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deletePlan({ commit, dispatch }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/subscriptions')
          .then((res) => {
            commit('addAlerts', {
              type: 'primary',
              text: 'Subscription is deleted',
            })
            commit('setActivePlan', null)
            dispatch('fetchMe')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getGoogleLink({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/accounts/google/link')
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveGoogleAccount({ commit, dispatch }, code) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/accounts/google', { code })
          .then((res) => {
            resolve(res)
            dispatch('fetchMe')
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchGoogleAccounts({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/accounts/google/accounts')
          .then((res) => {
            commit('setGoogleAccounts', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchGoogleCustomers({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/accounts/google/accounts/' + id)
          .then((res) => {
            commit('setGoogleCustomers', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveGoogleCustomers({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/accounts/google/accounts', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getFacebookLink({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/accounts/facebook/link')
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addFacebookAccount({ commit, dispatch }, code) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/accounts/facebook', { code })
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: res.data.message })
            dispatch('fetchMe')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchFacebookAccounts({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/accounts/facebook/accounts')
          .then((res) => {
            commit('setFacebookAccounts', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchFacebookPage({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/accounts/facebook/accounts/' + id)
          .then((res) => {
            commit('setFacebookPage', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveFacebookAccounts({ commit, dispatch }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/accounts/facebook/accounts', data)
          .then((res) => {
            dispatch('fetchMe')
            commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPortalLink({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/subscriptions/portal')
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default setting
