import axios from '@/plugins/axios'

const ads = {
  state: {
    budgets: [],
  },
  mutations: {
    setBudgets(state, data) {
      state.budgets = data
    },
  },
  getters: {
    budgets: (state) => state.budgets,
  },
  actions: {
    fetchBudget({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/budgets')
          .then((res) => {
            commit('setBudgets', res.data.data)
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createBudget({ commit, dispatch }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/budgets', data)
          .then((res) => {
            dispatch('fetchMe')
            dispatch('fetchBudget')
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateBudget({ commit, dispatch }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/user/budgets/' + data.id, data)
          .then((res) => {
            dispatch('fetchMe')
            dispatch('fetchBudget')
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteBudget({ commit, dispatch }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/user/budgets/' + id)
          .then((res) => {
            dispatch('fetchMe')
            dispatch('fetchBudget')
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ads
