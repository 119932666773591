<template>
  <CFooter>
    <div>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} AdMenu.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
