<template>
  <CSidebar
    color-scheme="light"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="d-none d-md-flex">
      <img
        class="sidebar-brand-full ms-3"
        src="@/assets/images/logo-white.png"
        alt="Logo"
        height="35"
      />
      <img
        class="sidebar-brand-narrow"
        src="@/assets/images/small-logo.png"
        alt="Logo"
        height="35"
      />
      <CSidebarToggler @click="$store.commit('toggleUnfoldable')" />
    </CSidebarBrand>
    <AppSidebarNavUser v-if="type === 1 || type === 2 || type === 3" />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNavUser } from './AppSidebarNavUser'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import { mapGetters } from 'vuex'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNavUser,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  watch: {
    getUser() {
      if (this.getUser) {
        this.active_budget = this.getUser.active_budget
        this.active_budget_get = true
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    type() {
      return this.$store.getters.getUser && this.$store.getters.getUser.type
    },
  },
}
</script>
