import axios from '@/plugins/axios'

const texts = {
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = { ...state.user, ...user }
    },
    removeUser(state) {
      state.user = null
    },
  },
  getters: {
    getUser: (state) => state.user,
    getRole: (state) => (state.user ? state.user.user_type : null),
    getPlan: (state) => (state.user ? state.user.active_subscription : null),
    getPermissions: (state) => (state.user ? state.user.abilities : null),
  },
  actions: {
    login({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login', data)
          .then((res) => {
            localStorage.setItem('tokenAdmenu', res.data.access_token)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data.access_token
            axios
              .get('/auth/me')
              .then((res) => {
                commit('setUser', { ...res.data.data })
                resolve(res)
              })
              .catch((error) => {
                reject(error)
              })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchMe({ commit }) {
      commit('setLoader', true)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('tokenAdmenu')
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/me')
          .then((res) => {
            commit('setUser', { ...res.data.data })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logout({ commit }) {
      axios.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem('tokenAdmenu')
      commit('removeUser')
    },
    loginSocial({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login/social', data)
          .then((res) => {
            localStorage.setItem('tokenAdmenu', res.data.access_token)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data.access_token
            axios
              .get('/auth/me')
              .then((res) => {
                commit('setUser', { ...res.data.data })
                resolve(res)
              })
              .catch((error) => {
                reject(error)
              })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    signUp({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/register', data)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: 'User is created' })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resendEmail({ commit }, email) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/resend_verification', { email: email })
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: 'Email is sended' })
            resolve(res)
          })
          .catch((error) => {
            commit('addAlerts', {
              type: 'warning',
              text: 'Email is not sended',
            })
            reject(error)
          })
      })
    },
    signUpSocial({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login/social', data)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: 'User is created' })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    forgotPassword({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/password/email', data)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetPassword({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/password/reset', data)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default texts
