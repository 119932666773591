import axios from '@/plugins/axios'

const ads = {
  state: {
    templateAds: [],
    templateAdsPage: 0,
    activeAds: [],
    oldsAds: [],
    oldsAdsPage: 0,
    googleAds: {
      groups: [],
      template: false,
      website_url: 'https://',
    },
  },
  mutations: {
    setTemplateAds(state, data) {
      state.templateAds = data
    },
    setTemplateAdsAdd(state, data) {
      state.templateAds = state.templateAds.concat(data)
    },
    setTemplateAdsPage(state, data) {
      state.templateAdsPage = data
    },
    setActiveAds(state, data) {
      state.activeAds = data
    },
    setOldsAds(state, data) {
      state.oldsAds = data
    },
    setOldsAdsPage(state, data) {
      state.oldsAdsPage = data
    },
    setOldsAdsAdd(state, data) {
      state.templateAds = state.templateAds.concat(data)
    },
    setGoogleAds(state, data) {
      state.googleAds = data
    },
    setGoogleAdsStatus(state, data) {
      const index = state.googleAds.groups.findIndex(
        (item) => item.group_id === data.id,
      )
      if (index !== -1) state.googleAds.groups[index].active = data.active
    },
    setGoogleAdsUrl(state, data) {
      if (data === 'www') {
        state.googleAds.website_url = 'https://' + data
      } else {
        state.googleAds.website_url = data
      }
    },
    setGoogleAdsTemplate(state, data) {
      state.googleAds.template = data
    },
  },
  getters: {
    templateAds: (state) => state.templateAds,
    templateAdsPage: (state) => state.templateAdsPage,
    activeAds: (state) => state.activeAds,
    oldsAds: (state) => state.oldsAds,
    oldsAdsPage: (state) => state.oldsAdsPage,
    googleAds: (state) => state.googleAds,
  },
  actions: {
    fetchTemplateAds({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/templates?page=1')
          .then((res) => {
            commit('setTemplateAds', res.data.data)
            commit(
              'setTemplateAdsPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextTemplateAds({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/templates?page=${getters.templateAdsPage + 1}`)
          .then((res) => {
            commit('setTemplateAdsAdd', res.data.data)
            commit(
              'setTemplateAdsPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createAds({ commit, dispatch }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/user-ads', data)
          .then((res) => {
            dispatch('fetchMe')
            commit('addAlerts', { type: 'primary', text: 'Ad is created' })
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchActiveAds({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/user-ads')
          .then((res) => {
            commit('setActiveAds', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchOldsAds({ commit }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/user/user-ads/old?page=1')
          .then((res) => {
            commit('setOldsAds', res.data.data)
            commit(
              'setOldsAdsPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchNextOldsAds({ commit, getters }) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/user-ads/old?page=${getters.templateAdsPage + 1}`)
          .then((res) => {
            commit('setOldsAdsAdd', res.data.data)
            commit(
              'setOldsAdsPage',
              res.data.meta.last_page === res.data.meta.current_page
                ? -1
                : res.data.meta.current_page,
            )
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteFBAds({ dispatch, commit }, payload) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/user-ads/${payload.id}`)
          .then(() => {
            dispatch('fetchActiveAds')
            dispatch('fetchOldsAds')
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchGoogleAds({ commit }, date) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/user/user-ads/google?year=${date.year}&month=${date.month}&type=${date.type}`,
          )
          .then((res) => {
            commit('setGoogleAds', {
              ...res.data.data,
              website_url: res.data.data.website_url || 'https://',
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    saveGoogleAds({ getters, commit, dispatch }, type) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/user-ads/google`, {
            ...getters.googleAds,
            groups: getters.googleAds.groups
              .filter((i) => i.active)
              .map((item) => ({
                active: item.active,
                group_id: item.group_id,
                name: item.name,
              })),
            type: type,
          })
          .then((res) => {
            dispatch('fetchMe')
            commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ads
